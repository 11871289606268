(function($) {
    if (!html.hasClass("ie")) {
        doc.on("change", ".part_ui_input", function () {
            if (!$(this).filter("[data-no-validation]").length) {
                $(this).validateInput();
            }
        });

        $.libInit(".part_ui_input", "lib_input", function(selector) {
            $(selector).each(function () {
                if (!$(this).filter("[data-no-validation]").length && !$(this).hasClass("state--invalid")) {
                    $(this).validateInput();
                }
            });
        });
    }
})(jQuery);