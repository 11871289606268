(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        $.lib_flickity(function () {
            selector.each(function() {
                let carousel = $(this).find("[data-carousel]");
                carousel.flickity({
                    setGallerySize: true,
                    contain: true,
                    wrapAround: true,
                    prevNextButtons: true,
                    pageDots: true,
                    cellAlign: "left",
                    autoPlay: false,
                    percentPosition: false,
                })
            });
        });

        window.lui[name] = comp;
    }
})("comp_slider_image");