(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let fns = {};

        (function fn_gallery(){
            let gallery = selector.find("[data-gallery]");

            $.importScript(cdnjs.lightgallery,function () {
                gallery.lightGallery({
                    thumbnail: true,
                    selector: "[data-exthumbimage]",
                    exThumbImage: 'data-exthumbimage',
                    fullScreen: false,
                    zoom: true,
                    actualSize: false,
                    hash: false,
                    download: true,
                    autoplay: false,
                    autoplayControls: false,
                })
            });

            fns.fn_gallery = fn_gallery;
        })();


        window.lui[name] = comp;
        window.lui[name].fns = fns;
    }
})("comp_gallery");