let $ = jQuery;
let doc = $(document),
    win = $(window),
    html = $("html"),
    body = $("body"),
    lang = html.attr('lang');

window.lui = {
    lib: {}
};

if ('serviceWorker' in navigator && location.protocol === "https:") {
    window.addEventListener('load', function() {
        if (!html.hasClass("no-sw")) {
            navigator.serviceWorker.register('/sw.js').catch(function(e) {
                console.error('Error during service worker registration:', e);
            });
        } else {
            navigator.serviceWorker.getRegistrations().then(function(registrations) {
                if (registrations.length > 0) {
                    for(let registration of registrations) {
                        registration.unregister()
                    }
                }
            });
        }
    });
}

(function() {
    cssLoaded(function(){
        body.removeClass("body--preload");
        setTimeout(function () {
            if ($("[data-load-facebook-chat]").length) {
                setTimeout(function(){
                    $("script[data-load-facebook-chat]").each(function(){
                        let elm = $(this);
                        let script = document.createElement("script");

                        elm.each(function() {
                            $.each(this.attributes, function() {
                                if(this.specified) {
                                    if (this.name.indexOf("data-load-facebook-chat") === -1 && this.name.indexOf("type") === -1) {
                                        script.setAttribute(this.name, this.value);
                                    }
                                }
                            });
                        });

                        script.innerHTML = elm[0].innerHTML;

                        document.body.appendChild(script);
                        elm.remove();
                    });
                },2000);
            }
            body.addClass("body--loaded");
        }, 300);
    });
})();