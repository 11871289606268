(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let fns = {};

        (function fn_image(){
            let file = selector.find("[type=\"file\"]");

            file.change(function(){
                if (this.files && this.files[0]) {
                    let reader = new FileReader(),
                        img = $(this).closest(".wrp_part__upload").find("img");

                    reader.onload = function (e) {
                        img.attr('src', e.target.result);
                    };
                    reader.readAsDataURL(this.files[0]);
                }
            });

            fns.fn_image = fn_image;
        })();


        window.lui[name] = comp;
        window.lui[name].fns = fns;
    }
})("comp_advice");