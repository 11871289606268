(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        selector.on("change",".part_form_basket_data [data-toggle] input", function () {
            let row = $(this).closest("[data-toggle]").next(".elm_row");
            if ($(this).prop("checked") === true) {
                row.slideDown(300);
            } else {
                row.slideUp(300);
            }
        });

        doc.on('change','input[type="number"][data-ajax-link]',function() {
            var ajaxLink = $(this).attr('data-ajax-link');
            var count = $(this).val();

            $.ajax({
                url: ajaxLink,
                data: {count: count},
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload);
            });
        });

        doc.on('change','[data-service-item]',function() {
            var ajaxLink = $(this).attr('data-ajax-link');

            $.ajax({
                url: ajaxLink,
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload);
            });
        });

        doc.on('click','[data-add-voucher]',function() {

            let url = $(this).attr('data-ajax-link');
            let code = $('input[data-voucher-code]').val();

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json',
                cache: false,
                data: {
                    code: code
                }
            }).done(function (payload) {
                fn_ajaxHandler(payload);
            });

        });

        doc.on('click','[data-add-points]',function() {

            let url = $(this).attr('data-ajax-link');
            let elm = $(this);

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json',
                cache: false,
                data: {
                    set: elm.prop('checked') ? 1 : 0
                }
            }).done(function (payload) {
                fn_ajaxHandler(payload);
            });

        });

        doc.on('change', '[data-set-country]', function() {

            let url = $(this).attr('data-ajax-link');
            let elm = $(this);

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json',
                cache: false,
                data: {
                    country: elm.val()
                }
            }).done(function (payload) {
                fn_ajaxHandler(payload);
            });
        });

        doc.on('change', '[data-basket-new-address]', function(){
            $('[data-basket-delivery-data]').find('input, select').val('');
        });

        if($('.part_basket_shipping').length) {
            $(document).on('change','input[data-packetery]',function() {
                console.log('branch packetery choose');
                /*$('a.packeta-selector-branch-name').trigger('click')*/
            });
        }

        doc.on('change', '[data-change-map]', function(){
            $('[data-branch-map]').hide();
            $($(this).data('change-map')).show();
            $('[data-branch-id]').val($(this).val());
            $('[data-branch-name]').text($(this).closest('label').find('em span').first().text());
        });

        if ($('[name="packetery"]').length) {
            let packetery = '';
            setInterval(function () {
                let v = $('[name="packetery"]').val();
                let n = $('[name="packeteryBranchName"]').val();

                if (v !== packetery) {
                    $.ajax({
                        method: 'POST',
                        data: {'do': 'setPacketery', 'packetery': v, 'packeteryBranchName': n}
                    });

                    packetery = v;
                }
            }, 500);
        }

        window.lui[name] = comp;
    }
})("comp_basket");