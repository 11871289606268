(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        $.lib_flickity(function () {
            $.importScript(cdnjs.flickity_sync, function(){
                let carousel = selector.find("[data-carousel-main]"),
                    carousel_nav = selector.find("[data-carousel-nav]");
                carousel.flickity({
                    contain: true,
                    wrapAround: true,
                    prevNextButtons: false,
                    pageDots: false,
                    cellAlign: "left",
                    percentPosition: false,
                });

                if(carousel_nav.children().length > 3) {
                    carousel_nav.flickity({
                        contain: true,
                        wrapAround: true,
                        prevNextButtons: true,
                        pageDots: false,
                        cellAlign: "left",
                        sync: carousel[0],
                        percentPosition: false,
                    });
                }
                else {
                    carousel_nav.on("click",".elm_carousel__item",function () {
                        let el = $(this);
                        carousel.flickity("select",el.index(),true,false);
                    })
                }
            })
        });

        $.importScript(cdnjs.lightgallery,function () {
            let gallery = selector.find("[data-carousel-main]");

            gallery.lightGallery({
                thumbnail: true,
                selector: "[data-exthumbimage]",
                exThumbImage: 'data-exthumbimage',
                fullScreen: false,
                zoom: true,
                actualSize: false,
                hash: false,
                download: true,
                autoplay: false,
                autoplayControls: false
            });

            gallery.on('onBeforeOpen.lg',function(event){
                body.addClass("body--no-scroll");
            });
            gallery.on('onCloseAfter.lg',function(event){
                body.removeClass("body--no-scroll");
            });
        });

        //
        $(document).on('change','[data-product-variant]',function() {

            let variantId = $('[data-product-variant]:checked').val();
            let url = $(this).attr('data-ajax-link');

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json',
                data: {
                    variantId: variantId
                },
                cache: false
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    ScrollReveal().sync();

                });
            });

        });

        window.lui[name] = comp;
    }
})("comp_product_detail");