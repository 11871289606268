(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        $.lib_flickity(function () {
            selector.each(function() {
                let carousel = $(this).find("[data-carousel]");
                carousel.flickity({
                    contain: true,
                    wrapAround: true,
                    prevNextButtons: true,
                    pageDots: false,
                    cellAlign: "left",
                    percentPosition: false,
                    watchCSS: carousel.children().length < 5,
                });

                let calcHeight = debounce(function () {
                    if(carousel.find(".flickity-viewport").length) {
                        carousel.find(".flickity-viewport").css("height", 0);
                        carousel.flickity('resize');
                        carousel.find(".flickity-viewport").css("height", null);
                    }
                },150);

                win.on("resize",calcHeight);
            });
        });

        function debounce(func, wait, immediate) {
            let timeout;
            return function() {
                let context = this, args = arguments;
                let later = function() {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                };
                let callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
            };
        };

        window.lui[name] = comp;
    }
})("comp_carousel_products");