(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        $.importScript(cdnjs.simplebar,function () {
            selector.on("click","[data-menu] > a",function (e) {
                e.preventDefault();

                let el = $(this),
                    submenu = el.next(".elm_nav__submenu");

                $.fn.close = function () {
                    $(this).slideUp(function () {
                        el.removeClass("state--toggled");
                    });
                };

                $.fn.open = function () {
                    $(this).slideDown(function () {
                        el.addClass("state--toggled");
                    });
                };

                if(!el.hasClass("state--toggled")){
                    submenu.open();
                    setTimeout(function () {
                        html.one("click",function (e) {
                            if(!$(e.target).closest("[data-menu]").length) {
                                submenu.close();
                            }
                        });
                    },500);
                }
                else {
                    submenu.close();
                }
            });

            selector.on("click","[data-submenu]",function (e) {
                e.preventDefault();
                let el = $(this),
                    submenu = el.next(".elm_submenu__submenu");

                $.fn.close = function () {
                    $(this).slideUp(function () {
                        el.removeClass("state--toggled");
                    });
                };

                $.fn.open = function () {
                    $(this).slideDown(function () {
                        el.addClass("state--toggled");
                    });
                };

                if(!el.hasClass("state--toggled")){
                    el.closest(".elm_nav__submenu").find(".elm_submenu__submenu").not(submenu).each(function () {
                        $(this).prev("[data-submenu]").removeClass("state--toggled");
                        $(this).close();
                    });
                    submenu.open();
                }
                else {
                    submenu.close();
                }
            });

            selector.on("click","[data-reference-dialog]",function () {
                let el = $(this);
                $.ajax({
                    dataType: "json",
                    data: {ajax: +new Date},
                    url: el.data('reference-dialog')
                }).done(function(data) {
                    fn_lib_dialog().open(data.dialog, function () {
                        let dialog = doc.find(".part_dialog_reference"),
                            upload = dialog.find(".wrp_part__upload"),
                            file = upload.find("[type=\"file\"]"),
                            img = upload.find("img");

                        file.change(function(){
                            if (this.files && this.files[0]) {
                                let reader = new FileReader();

                                reader.onload = function (e) {
                                    img.attr('src', e.target.result);
                                };
                                reader.readAsDataURL(this.files[0]);
                            }
                        });

                    });
                });
            })
        });

        window.lui[name] = comp;
    }
})("comp_list_references");