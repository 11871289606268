var reInitGCaptcha;
var initInicials;
var scrollToElement;

(function($) {

    scrollToElement = function(selector) {
        $([document.documentElement, document.body]).animate({
            scrollTop: ($("#" + selector).offset().top - $('#layout_header').outerHeight())
        }, 1000);
    };

    reInitGCaptcha = function() {
        $.getScript("https://www.google.com/recaptcha/api.js?render=6LdJVn0UAAAAAB8IXcIFDUnuV51wtj7D_TaCQ7R4").done(function () {
            grecaptcha.ready(function () {

                $('form[data-recaptcha]').each(function() {
                    let form = $(this);
                    let type = $(this).attr('data-recaptcha');

                    grecaptcha.execute('6LdJVn0UAAAAAB8IXcIFDUnuV51wtj7D_TaCQ7R4', {action: type})
                        .then(function (token) {
                            form.find('[data-g-token]').val(token);
                        });

                });
            });
        });
    };

    initInicials = function() {
        let iImages = $("img.mod--default");

        if(iImages.length){
            iImages.initial({
                charCount: 1,
                height: 75,
                width: 75
            })
        }
    };
    initInicials();

    if ($('form[data-recaptcha]').length) {
        reInitGCaptcha();
    }

    cssLoaded(function(){
        $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();
    });

    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
    });

    nl_lib_dialog.init(function() {
        Object.keys(window.lui.lib).forEach(function(key) {
            let dialog_selector = doc.find(".lib--dialog");
            let s = window.lui.lib[key].selector;

            if (dialog_selector.find(s[0]).length) {
                window.lui.lib[key](dialog_selector.find(s[0]),s[1])
            }
        });
    });

    $.libInit(".part_ui_link, .part_ui_btn, .part_ui_checkbox, .part_ui_radio, .part_ui_icon", "lib_ripple", function(selector) {
        $(selector).nl_lib_ripple();
    });

    $.libInit("[data-lib-switch]", "lib_switch", function(selector) {
        $(selector).nl_lib_switch();
    });

    (function fn_ajaxEvents() {
        doc.on('click', '[href].ajax', function (e) {
            e.preventDefault();
            let $el = $(this);

            $.ajax({
                url: $el.attr('href'),
                data: {ajax: +new Date},
                dataType: "json",
                success: function (payload) {
                    fn_ajaxHandler(payload);
                }
            });
        });

        doc.on('submit', 'form.ajax,form[data-component-ajax]', function (e) {
            e.preventDefault();
            let frm = $(this),
                formData = new FormData($(this)[0]);

            $.ajax({
                method: frm.attr('method'),
                url: frm.attr('action'),
                data: formData,
                processData: false,
                contentType: false,
                dataType: "json",
                success: function (payload) {
                    fn_ajaxHandler(payload,function() {
                        if ($('form[data-recaptcha]').length) {
                            reInitGCaptcha();
                        }

                        if(typeof payload.payload.redrawDialog !== 'undefined') {
                            nl_lib_dialog.open(payload.dialog);
                        }

                        initInicials();

                    });
                }
            });
        });

        doc.on('click', 'a[data-component-ajax]', function (e) {
            e.preventDefault();
            let url = $(this).attr('data-href');
            if (!url || !url.length) {
                url = $(this).attr('href');
            }

            if (!url || !url.length) {
                console.log('no href attribute for ajax component link');
                return;
            }

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    ScrollReveal().sync();
                    initInicials();

                    if(typeof payload.payload.scrollTo !== 'undefined') {
                        scrollToElement(payload.payload.scrollTo);
                    }

                });
            });
        });
    })();

    (function fn_pictureFill() {
        let picture = document.createElement('picture'),
            strObj = '' + picture;

        if(strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie"))
        {
            $.importScript(cdnjs.picturefill);
        }
    })();

    (function fn_gridFill() {
        if (typeof html[0].style.grid !== 'string') {
            html.addClass("no-grid");
        }
    })();

    (function fn_cookieConsent() {
        if ($(".part_cookie_consent").length || $(".part_form_cookies").length) {
            $.importScript(cdnjs.cookieconsent);
        }
    })();

    doc.on('change', '[data-show-item-toggle]', function (e) {
        e.preventDefault();

        let toggleIndex = $(this).attr('data-show-item-toggle');

        let targetItem = $('[data-show-item="' + toggleIndex + '"]');
        let targetItemRequired = $('[data-show-item-required="' + toggleIndex + '"]');

        if(targetItem.length) {
            if(!targetItem.is(':visible')) {
                targetItem.show();

                if(targetItemRequired.length) {
                    targetItemRequired.attr('required','required');
                }

            } else {
                targetItem.hide();

                if(targetItemRequired.length) {
                    targetItemRequired.removeAttr('required');
                }

            }
        }

        if($('[data-show-item-toggle]:checked').length) {
            $('[data-show-item-first-required]').removeAttr('required');
        } else {
            $('[data-show-item-first-required]').attr('required','required');
        }

    });

})(jQuery);