(function($) {
    const fn = $("#layout_header");

    if (fn.length) {
        let search = fn.find(".elm_search").first();

        $.importScript(cdnjs.touchswipe, function() {
            fn.after('<nav id="layout_nav" class="ssm-nav" aria-hidden="true"><div class="elm_content"></div></nav>');

            let layout_nav = doc.find("#layout_nav"),
                nav = fn.find(".elm_nav").find("ul").first().clone();

            nav.find(".col--logo").after(search.clone());
            nav.find("button").prop("type","submit");
            layout_nav.find(".elm_content").append(nav);

            $('.ssm-nav').slideAndSwipe();
        });

        $.importScript(cdnjs.headroom, function(){
            let headroom = new Headroom(fn[0], {
                offset: 0,
                tolerance : {
                    up : 5,
                    down : 0
                },
                classes: {
                    "initial" : "headroom",
                    "pinned": "headroom--pinned",
                    "unpinned": "headroom--unpinned",
                    "top" : "headroom--top",
                    "notTop" : "headroom--not-top"
                }
            });
            headroom.init();
        });

        search.on("click","button",function (e) {
            let btn = $(this),
                input = search.find("input");

            if(btn.is("[type=\"button\"]")) {
                input.fadeIn(function () {
                    btn.prop("type","submit");
                    input.focus();

                    input.on("blur",function () {
                        let timeout = setTimeout(function () {
                            btn.prop("type","button");
                            input.fadeOut();
                        },1000);

                        input.on("focus",function () {
                            clearTimeout(timeout);
                        })
                    });
                })
            }
        });

        let links = fn.find(".elm_nav__item"),
            timeout;

        links.on("mouseenter",function(){
            let el = $(this);
            clearTimeout(timeout);
            $.fn.close = function() {
                let elm = $(this);
                elm.removeClass("state--toggled");
                timeout = setTimeout(function(){
                    elm.attr("aria-expanded","false");
                },300);
            };
            $.fn.open = function() {
                let el = $(this);
                el.addClass("state--toggled").attr("aria-expanded","true");
                if(!el.next(".elm_nav__submenu").length) {
                    el.one("mouseleave",function () {
                        el.close();
                    })
                }
                else {
                    fn.one("mouseleave",function () {
                        setTimeout(function () {
                            el.close();
                        },300);
                    })
                }
            };

            html.one("click", function() {
                el.close();
            });
            win.one("scroll", function() {
                el.close();
            });
            if (!el.hasClass("state--toggled")) {
                el.open();
            }
            links.not(el.closest(".elm_nav__item")).close();
        });

    }
})(jQuery);