(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let fns = {};

        (function fn_masonry(){
            let grid = selector.find("[data-masonry]");

            if(grid.length) {
                cssLoaded(function () {
                    $.importScript(cdnjs.masonry,function () {
                        grid.masonry({
                            itemSelector: ".col--masonry",
                            columnWidth: ".col--masonry",
                            percentPosition: false,
                            horizontalOrder: true
                        })
                    });
                });
            }

            fns.fn_masonry = fn_masonry;
        })();


        window.lui[name] = comp;
        window.lui[name].fns = fns;
    }
})("comp_list_blog");