var doCatalogFilter;

(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let categories = selector.find(".elm_category__item");
        let sorting = selector.find(".elm_sorting__item");
        let content = selector.find(".wrp_comp__content");
        let problems = selector.find(".elm_problems");
        let problems_array = [];
        let filter_toggle = selector.find("[data-problems-toggle]").children("a");
        categories.on("click",function () {
            let el = $(this);
            categories.filter(".state--active").removeClass("state--active");
            el.addClass("state--active");

            doCatalogFilter();
        });

        sorting.on("click",function () {
            let el = $(this);
            sorting.filter(".state--active").removeClass("state--active");
            el.addClass("state--active");
            let type = $(this).data('type');

            if(type !== 'problems') {
                problems.stop().slideUp();
                problems_array = [];
                problems.find(".state--active").removeClass("state--active");
                doCatalogFilter();
            }
        });

        filter_toggle.on("click",function (e) {
            problems.stop().slideToggle();
        });

        problems.on("click","a",function () {
            let el = $(this),
                id = el.data("id");
            problems.find(".state--active").removeClass("state--active");
            el.toggleClass("state--active");
            // if(problems_array.indexOf(id) > -1){
            //     problems_array.splice(problems_array.indexOf(id),1);
            // }
            // else {
            //     problems_array.push(id);
            // }
            problems_array = [];
            problems_array.push(id);

            doCatalogFilter();
        });


        //filter
        doCatalogFilter = function() {
            let categoryId = $('.elm_category__item.state--active').attr('data-id');

            let sortBy = '';
            if($('.elm_sorting__item.state--active').length) {
                sortBy = $('.elm_sorting__item.state--active').attr('data-type');
            }

            let problems = problems_array;

            let url = selector.data('ajax-link');

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json',
                data: {
                    categoryId: categoryId,
                    sortBy: sortBy,
                    problems: problems
                }
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    ScrollReveal().sync();

                    if(typeof payload.payload.scrollTo !== 'undefined') {
                        scrollToElement(payload.payload.scrollTo);
                    }

                });
            });

        };

        window.lui[name] = comp;
    }
})("comp_list_products");