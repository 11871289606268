(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {

        doc.on('click', '[data-fill-ico]', function () {
            let val = $('[name="company_id"]').val();

            $.ajax({
                method: 'post',
                data: {getVatData: val, country: $('[name="country"]').val()},
                dataType: 'json'
            }).done(function (result) {
                if (result) {
                    $('[name="tax_id"]').val(result.tin);
                    $('[name="city"]').val(result.city);
                    $('[name="company"]').val(result.company);
                    $('[name="postcode"]').val(result.zip);
                    $('[name="street"]').val(result.street + ' ' + result.house_number);
                }
            });
        });

        window.lui[name] = comp;
    }
})("comp_benefits");
