(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {

        $(document).on('click','a[data-rate-type]',function(e) {
            e.preventDefault();

            let type = $(this).data('rate-type');
            let span = $(this).find('.icon');
            let wrap = $(this).closest('.wrp_part__foot');
            let likeElem = wrap.find('[data-rate-type="heart"]');
            let dislikeElem = wrap.find('[data-rate-type="thumb-down"]');
            let spanLike = likeElem.find('.icon');
            let spanDislike = dislikeElem.find('.icon');
            let numberLikeElem = wrap.find('[data-count="heart"]');
            let numberDislikeElem = wrap.find('[data-count="thumb-down"]');
            let numberLikes = parseInt(numberLikeElem.html());
            let numberDislikes = parseInt(numberDislikeElem.html());

            switch (type) {
                case 'heart':

                    if(wrap.is('[data-voted="thumb-down"]')) {
                        spanDislike.removeClass('icon--thumb-down-fill');
                        spanDislike.addClass('icon--thumb-down');
                        numberDislikes--;
                    }

                    if(span.hasClass('icon--heart')) {
                        span.removeClass('icon--heart');
                        span.addClass('icon--heart-fill');

                        wrap.attr('data-voted','heart');
                        numberLikes++;
                    } else if(span.hasClass('icon--heart-fill')) {
                        span.removeClass('icon--heart-fill');
                        span.addClass('icon--heart');

                        wrap.removeAttr('data-voted');
                        numberLikes--;
                    }

                    break;
                case 'thumb-down':

                    if(wrap.is('[data-voted="heart"]')) {
                        spanLike.removeClass('icon--heart-fill');
                        spanLike.addClass('icon--heart');
                        numberLikes--;
                    }

                    if(span.hasClass('icon--thumb-down')) {
                        span.removeClass('icon--thumb-down');
                        span.addClass('icon--thumb-down-fill');

                        wrap.attr('data-voted','thumb-down');
                        numberDislikes++;
                    } else if(span.hasClass('icon--thumb-down-fill')) {
                        span.removeClass('icon--thumb-down-fill');
                        span.addClass('icon--thumb-down');

                        wrap.removeAttr('data-voted');
                        numberDislikes--;
                    }

                    break;
            }

            numberLikeElem.html(numberLikes);
            numberDislikeElem.html(numberDislikes);

        });

        window.lui[name] = comp;
    }
})("part_item_reference");