(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {

        doc.on('change', '[data-change-user-type]', function(){
            var val = $(this).val();

            if(val == 1) {
                $('[data-company-group]').hide();
                $('[name="company"]').prop('required', false);
                $('[name="company_id"]').prop('required', false);
            } else {
                $('[data-company-group]').show();
                $('[name="company"]').prop('required', true);
                $('[name="company_id"]').prop('required', true);
            }
        });

        doc.on('change', '[name="company_id"]', function () {
            var val = $(this).val();

            $.ajax({
                method: 'post',
                data: {getVatData: val, country: $('[name="country"]').val()},
                dataType: 'json'
            }).done(function (result) {
                if (result) {
                    $('[name="tax_id"]').val(result.tin);
                    $('[name="city"]').val(result.city);
                    $('[name="company"]').val(result.company);
                    $('[name="postcode"]').val(result.zip);
                    $('[name="street"]').val(result.street + ' ' + result.house_number);
                }
            });
        });

        doc.on('click', '[data-cancel-account]', function () {
            setTimeout(function () {
                location.href = window.location;
            }, 500);
        });

        window.lui[name] = comp;
    }
})("comp_package_extranet");
